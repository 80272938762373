import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
const ContestRegistration = () => {
  const language = useSelector((state) => state.language);
  //const DemoContestData = DemoContestQuery(language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [selectedMonth, setSelectedMonth] = useState(1);
  const [selectedYear, setSelectedYear] = useState(2023);

  const handleMonthChange = (e) => {
    setSelectedMonth(parseInt(e.target.value, 10));
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };


  const generateRegistrationDates = () => {
    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const registrationStart = `1 ${new Date(selectedYear, selectedMonth - 1, 1)
      .toLocaleDateString('en-US', { month: 'long' })}, ${selectedYear}`;
    const registrationEnd = `${daysInMonth} ${new Date(selectedYear, selectedMonth - 1, 1)
      .toLocaleDateString('en-US', { month: 'long' })}, ${selectedYear}`;

    // Change year for December
    const durationStart = selectedMonth === 12 ? `1 January, ${selectedYear + 1}` : `1 ${new Date(selectedYear, selectedMonth, 1)
      .toLocaleDateString('en-US', { month: 'long' })}, ${selectedYear}`;
    const durationEnd = selectedMonth === 12 ? `31 December, ${selectedYear + 1}` : `${daysInMonth} ${new Date(selectedYear, selectedMonth - 1, 1)
      .toLocaleDateString('en-US', { month: 'long' })}, ${selectedYear}`;


    return (
      <div>
        <p>Contest Registration: {registrationStart} to {registrationEnd}</p>
        <p>Contest Duration: {durationStart} to {durationEnd}</p>
      </div>
    );
  };

  return (
    <div>
      <label>
        Select Month:
        <select value={selectedMonth} onChange={handleMonthChange}>
          {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
            <option key={month} value={month}>
              {new Date(selectedYear, month - 1, 1).toLocaleDateString('en-US', { month: 'long' })}
            </option>
          ))}
        </select>
      </label>
      <label>
        Select Year:
        <input
          type="number"
          value={selectedYear}
          onChange={handleYearChange}
        />
      </label>

      {generateRegistrationDates()}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(ContestRegistration); 